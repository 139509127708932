<template>
  <div class="refundWrap">
	<!-- id="refundContent" @scroll="handleScroll($event)" -->
		<div class="refund">
			<div class="noRefund" v-if="list.length == 0">
				<img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
				<div>您没有可退款订单</div>
				<div class="num">如有疑问请咨询客服热线：400 009 9713</div>
			</div>
			<div v-else class="refund-content" v-for="(item,index) in list" :key="index">
				<div class="refund-content-top">
					<div class="refund-content-top-left">
						<img class="refund-img" :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/${
								item.item_name == '优生活会员' ? 'icon_02' : 'icon_01'}.png`"></img>
					</div>
					<div class="refund-content-top-right">
						<div class="refund-title">
							<div class="name">{{item.item_name}}</div>
							<div class="price">￥{{item.item_price}}元</div>
						</div>
					</div>
				</div>
				<div class="refund-content-bottom">
					<div class="content-bottom-detials">
						<div class="detials time">交易时间:{{item.create_time}}</div>
						<div class="detials id">交易单号:{{item.pay_id}}</div>
					</div>
					<div class="content-bottom-btn" v-if="item.refunded == null" @click="handleRefund(item)">退款</div>
					<div class="content-bottom-btn" v-if="item.refunded == false" @click="haveRefund()">退款中</div>
				</div>
			</div>
		</div>
		<v-alert
      		:visibility.sync="alert.visibility"
			:title="alert.title"
			:message="alert.message"
			:rulestype="alert.rulestype"
			@handleSubmit="handleSubmit"
			/>
	</div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { getRefundable ,refundSingle} from "@/api/huimo/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
    VAlert
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      list:[],
	  equityEndList:[],
      page: 1,
      orderStatus:'订购中',
      currentIndex:0,
	  buyItemStatusTip: '', 
    };
  },
  computed: {
    ...mapGetters(["taiyue_token", "taiyue_user_info","taiyue_wechat_open_id"]),
  },
  created() {
	this.refundableList();
  },
  methods: {
    async refundableList() {
		this.list = [];
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await getRefundable(this.taiyue_token)
        .then(res =>{
          if (res.code == 0) {
			this.pageCount = res.pages;
			if(this.page == 1){
				this.list = res.record;
			}else{
				this.list = this.list.concat(res.record)
			}
			this.list = res.record.filter(item => item.refundable === true && item.refunded != true)
              this.$toast.clear();
          } else {
              this.$toast.clear();
          }
        }).catch(err =>{
          this.$toast.clear();
		  this.$router.replace({
            path: "/hz_huimo/receive/login",
            query: {
              wechat_open_id: this.taiyue_wechat_open_id,
			  from: "refund"
            },
          });
        })
    },
	haveRefund(){
		this.$toast({
          duration: 8000,
          message: "预计24小时内原路退回，请耐心等待",
        });
	},
    handleRefund(item){
		this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
		refundSingle(this.taiyue_token,{'trade_no': item.pay_id}).then((res)=>{
			 if (res.code == 0) {
              this.buyItemStatusTip = '发起退款成功';
              this.exchangeStatus = true;
              this.alert = {
                visibility: true,
                message: res.message,
                title: this.buyItemStatusTip,
              };
			  setTimeout(() => {
				this.refundableList();
			}, 2000)
			} else {
				this.buyItemStatusTip = '发起退款失败';
				this.alert = {
					visibility: true,
					message: res.message,
					title: this.buyItemStatusTip,
				};
			}
			this.$toast.clear();
		}).catch((err)=>{
			
			this.$toast.clear();
		})
    },
	handleSubmit(){
      this.visibility = false;
    },
	//  // 获取页面滚动距离
	//  handleScroll(e) {
	// 	let height = document.getElementById("refundContent").offsetHeight;
	// 	let scrollTop = e.srcElement.scrollTop || e.target.scrollTop;
	// 	let scrollHeight = e.srcElement.scrollHeight || e.target.scrollHeight;
	// 	if (scrollTop + height >= scrollHeight - 100) {
	// 		if (this.page == this.pageCount) {
	// 			return
	// 		}else{
	// 			this.page++;
	// 			this.refundableList();
	// 		}
	// 	}
	// },
  },
  
};
</script>

<style lang="scss" scoped>
.refundWrap{
		width: 100%;
		height: 100%;
		position: relative;
	}
	.refund{
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		color: #fff;
		font-size: 24px;
		background-color: #F7F7F7;
		/* 会员未开通 */
		.noRefund{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			
			img{
				width: 354px;
				margin-top: 170px;
			}
			div{
				// width: 100%;
				height: 50px;
				font-size: 30px;
				color: #999999;
				font-size: 28px;
			}
			.num{
				font-size: 24px;
			}
		}
		.refund-content{
			width: 620px;
			height: 223px;
			background: #FFFFFF;
			box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.06);
			border-radius: 8px;
			margin: 20px auto;
			padding: 30px;
			border-radius: 20px;
			color: #000;
			.refund-content-top{
				width: 100%;
				height: 72px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.refund-content-top-left{
					width: 100px;
					height: 72px;
					float: left;
					margin-right: 10px;
					.refund-img{
						width: 72px;
						height: 72px;
					}
					
				}
				.refund-content-top-right{
					width: calc(100% - 100px);
					height: 72px;
					float: right;
					.refund-title{
						float: left;
						width: 100%;
						height: 72px;
						line-height: 72px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						.name{
							font-size: 32px;
						}
						.price{
							font-size: 30px;
							color: #FF5000;
						}
					}
				}
			}
			.refund-content-bottom{
				width: 100%;
				height: 100px;
				margin-top: 40px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.content-bottom-detials{
					width: calc(100% - 150px);
					height: 100px;
					float: left;
					
					.detials{
						color: #999999;
						line-height: 40px;
						text-align: left;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 26px;
					}
					.time{
						margin-bottom: 20px;
					}
					.id{
						width: 100%;
						height: 50px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				.content-bottom-btn{
					float: right;
					width: 126px;
					height: 62px;
					background: #FFFFFF;
					border-radius: 31px;
					border: 2px solid #E3E3E3;
					border-radius: 30px;
					text-align: center;
					line-height: 62px;
					color: #999999;
				}
			}
			
			
		}
	}
</style>
